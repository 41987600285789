@fontface {
  font-family: 'Bungee Inline', cursive;
  src: url('https://fonts.googleapis.com/css?family=Poppins:400,500,600')
}

body {
  font-family: "Poppins", sans-serif;
}

/* Hide first column of datatable */
.dataTables_wrapper tr td:nth-child(2), 
.dataTables_wrapper th:nth-child(2) {
    display: none;
}

.mail-list button:focus {
    box-shadow: none;
}

.crossBtn {
margin-left: 15px;
    border: 1px solid;
    width: 22px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}

ul#attachments1 {
  height: 100px;
  padding: 0;
  max-height: 100px;
  overflow: auto;
}

ul#attachments1 li {
  background-color: #f5f5f5;
  padding: 3px 10px;
}

.my_drop
{
  padding: 10px;
  margin: 10px;
  border: 1px dotted #000000;
}

.dld {
    width: 40px;
    align-self: center;
}

.input-group .react-datepicker-wrapper {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.react-datepicker__input-container {
    width: 100%;
}